import store from '@/store/index'
function deshacer_eliminado(detalles_pedido) {

    let items = store.state.pedidos.item_para_deshacer
    if (items.para_mostrar
    ) {
        for (var i in store.state.pedidos.detalles_update) {
            if (store.state.pedidos.detalles_update[i].producto == items.para_enviar.producto) {

                store.state.pedidos.detalles_update.splice(i, 1);

                break;
            }
        }
        detalles_pedido.push(items.para_mostrar);
    }
    store.commit('pedidos/close_item_para_deshacer');

    // store.state.pedidos.deshacer.state = false
    // store.state.pedidos.item_para_deshacer.para_mostrar = {}
    // store.state.pedidos.item_para_deshacer.para_enviar = {}
}
function quit_detalle_update(detalles_pedido, detalle) {
    const index = detalles_pedido.indexOf(detalle);

    if (detalle.producto) {
        if (index > -1) {
            // index_register contiene el indice del array detalle_pedido, que se utiliza para enviar en formato correcto al servidor

            detalles_pedido.splice(index, 1);
            return detalles_pedido

        }
        let detalle_pedido = {
            detallePedido_id: detalle.id,
            producto: detalle.producto.id,
            cantidad: detalle.cantidad,
            accion: 0
        }
        let existeDetalleParaEnviar = store.state.pedidos.detalles_update.find(d => d.producto && d.producto == detalle_pedido.producto);

        if (existeDetalleParaEnviar) {
            //"Esta en para enviar");

            if (existeDetalleParaEnviar.accion == 1) {
                //"Y fue modificado, por lo que existia ya en el server");
                //"Lo seteo como eliminado nuevamente");
                for (var i in store.state.pedidos.detalles_update) {
                    if (store.state.pedidos.detalles_update[i].producto && store.state.pedidos.detalles_update[i].producto == detalle.producto.id) {
                        store.state.pedidos.detalles_update[i].accion = 0
                        store.state.pedidos.detalles_update[i].cantidad = detalle_pedido.cantidad;

                        break; //Stop this loop, we found it!
                    }
                }
                // lo quito de la vista");


                for (var i in detalles_pedido) {
                    // es el id del detalle
                    if (detalles_pedido[i].producto && detalles_pedido[i].producto.id == detalle.producto.id) {
                        store.commit('pedidos/set_item_para_deshacer_para_mostrar', detalles_pedido[i]);

                        // store.state.pedidos.item_para_deshacer = detalles_pedido[i]

                        detalles_pedido.splice(i, 1);
                        break;
                    }

                }
                store.commit('pedidos/active_item_para_deshacer', detalles_pedido[i]);




            } else {
                //Este detalle es nuevo, lo quito del para enviar");
                for (var i in store.state.pedidos.detalles_update) {
                    if (store.state.pedidos.detalles_update[i].producto && store.state.pedidos.detalles_update[i].producto == detalle.producto.id) {
                        store.commit('pedidos/set_item_para_deshacer_para_enviar', store.state.pedidos.detalles_update[i]);

                        store.state.pedidos.detalles_update.splice(i, 1);

                        break; //Stop this loop, we found it!
                    }
                }
                //"Tambien lo quito para mostrar");

                for (var i in detalles_pedido) {
                    // es el id del detalle
                    if (detalles_pedido[i].producto && detalles_pedido[i].producto.id == detalle.producto.id) {
                        store.commit('pedidos/set_item_para_deshacer_para_mostrar', detalles_pedido[i]);


                        detalles_pedido.splice(i, 1);
                        break;
                    }

                }
                store.commit('pedidos/active_item_para_deshacer', detalles_pedido[i]);


            }

        } else {
            /*El detalle no existia para enviar, se eliminara y");
            se guardara en para enviar. y lo quito de la vista");*/
            store.commit('pedidos/add_detalle_update', detalle_pedido);
            for (var i in detalles_pedido) {
                // es el id del detalle
                if (detalles_pedido[i].producto && detalles_pedido[i].producto.id == detalle.producto.id) {
                    store.commit('pedidos/set_item_para_deshacer_para_mostrar', detalles_pedido[i]);
                    store.commit('pedidos/set_item_para_deshacer_para_enviar', detalle_pedido);


                    detalles_pedido.splice(i, 1);
                    break;
                }
            }
            store.commit('pedidos/active_item_para_deshacer', detalles_pedido[i]);

        }
    } else {
        if (index > -1) {
            // index_register contiene el indice del array detalle_pedido, que se utiliza para enviar en formato correcto al servidor

            detalles_pedido.splice(index, 1);
            return detalles_pedido

        }
        let detalle_pedido = {
            detallePedido_id: detalle.id,
            promocion: detalle.promocion.id,
            cantidad: detalle.cantidad,
            accion: 0
        }
        let existeDetalleParaEnviar = store.state.pedidos.detalles_update.find(d => d.promocion && d.promocion == detalle_pedido.promocion);

        if (existeDetalleParaEnviar) {
            //"Esta en para enviar");

            if (existeDetalleParaEnviar.accion == 1) {
                //"Y fue modificado, por lo que existia ya en el server");
                //"Lo seteo como eliminado nuevamente");
                for (var i in store.state.pedidos.detalles_update) {
                    if (store.state.pedidos.detalles_update[i].promocion && store.state.pedidos.detalles_update[i].promocion == detalle.promocion.id) {
                        store.state.pedidos.detalles_update[i].accion = 0
                        store.state.pedidos.detalles_update[i].cantidad = detalle_pedido.cantidad;

                        break; //Stop this loop, we found it!
                    }
                }
                // lo quito de la vista");


                for (var i in detalles_pedido) {
                    // es el id del detalle
                    if (detalles_pedido[i].promocion && detalles_pedido[i].promocion.id == detalle.promocion.id) {
                        store.commit('pedidos/set_item_para_deshacer_para_mostrar', detalles_pedido[i]);

                        // store.state.pedidos.item_para_deshacer = detalles_pedido[i]

                        detalles_pedido.splice(i, 1);
                        break;
                    }

                }
                store.commit('pedidos/active_item_para_deshacer', detalles_pedido[i]);




            } else {
                //Este detalle es nuevo, lo quito del para enviar");
                for (var i in store.state.pedidos.detalles_update) {
                    if (store.state.pedidos.detalles_update[i].promocion && store.state.pedidos.detalles_update[i].promocion == detalle.promocion.id) {
                        store.commit('pedidos/set_item_para_deshacer_para_enviar', store.state.pedidos.detalles_update[i]);

                        store.state.pedidos.detalles_update.splice(i, 1);

                        break; //Stop this loop, we found it!
                    }
                }
                //"Tambien lo quito para mostrar");

                for (var i in detalles_pedido) {
                    // es el id del detalle
                    if (detalles_pedido[i].promocion && detalles_pedido[i].promocion.id == detalle.promocion.id) {
                        store.commit('pedidos/set_item_para_deshacer_para_mostrar', detalles_pedido[i]);


                        detalles_pedido.splice(i, 1);
                        break;
                    }

                }
                store.commit('pedidos/active_item_para_deshacer', detalles_pedido[i]);


            }

        } else {
            /*El detalle no existia para enviar, se eliminara y");
            se guardara en para enviar. y lo quito de la vista");*/
            store.commit('pedidos/add_detalle_update', detalle_pedido);
            for (var i in detalles_pedido) {
                // es el id del detalle
                if (detalles_pedido[i].promocion && detalles_pedido[i].promocion.id == detalle.promocion.id) {
                    store.commit('pedidos/set_item_para_deshacer_para_mostrar', detalles_pedido[i]);
                    store.commit('pedidos/set_item_para_deshacer_para_enviar', detalle_pedido);


                    detalles_pedido.splice(i, 1);
                    break;
                }
            }
            store.commit('pedidos/active_item_para_deshacer', detalles_pedido[i]);

        }
    }

}


function add_detalle_update(detalles_pedido, detalle) {
    /*
    Al servidor se envia aquellos detalles que sean nuevos, o que se hayan modificado.
    Por lo tanto, ellos se guardan en detalles_update. Estos detalles contienen una accion
    que el servidor toma para saber que hacer con él.
        Accion 0: Eliminar, 1:Modificar y 2: Agregar
    */
    const moduloInventario = store.state.sucursales.configuracion.productoInventario

    console.log("add_detalle_update, detalles_pedido: ", detalles_pedido);
    


    //Accion 0: Eliminar, 1:Modificar y 2: Agregar
    if (detalle.producto) {
        console.log(detalle.producto);
        
        let detalle_pedido = {
            producto: detalle.producto.id,
            cantidad: parseFloat(detalle.cantidad),
            accion: 2
        }
        console.log("add_detalle_update", detalle_pedido);

        let existeDetalle = store.state.pedidos.detalles_update.find(d => d.producto == detalle_pedido.producto.id);
            console.log("store.state.pedidos.detalles_update", store.state.pedidos.detalles_update);
            
        if (existeDetalle) {
            console.log("Existe el detalle para enviar, es: ", existeDetalle);

            //El detalle existia y fue eliminado
            if (existeDetalle.accion == 0) {
                console.log("Esta en el server, y fue eliminado");
                console.log("Pero ahora se agrego de nuevo, y es modificado");


                //Tiene que cambiar a la accion de modificar, y setear la cantidad
                for (var i in store.state.pedidos.detalles_update) {
                    if (store.state.pedidos.detalles_update[i].producto && store.state.pedidos.detalles_update[i].producto == detalle.producto.id) {
                        
                        store.state.pedidos.detalles_update[i].accion = 1
                        store.state.pedidos.detalles_update[i].cantidad = detalle_pedido.cantidad;

                        break; //Stop this loop, we found it!
                    }
                }
                //Tambien debe mostrarlo
                detalles_pedido.push(detalle);


            } else {
                console.log("Existe el detalle, y fue modificado");

                // Si existe para enviar y no fue por que se elimino
                //Actualizo la cantidad para enviar
                for (var i in store.state.pedidos.detalles_update) {
                    if (store.state.pedidos.detalles_update[i].producto && store.state.pedidos.detalles_update[i].producto == detalle.producto.id) {
                        if (detalle.producto.categoriaArbol.name.includes("PIZZA")){

                            detalle_pedido.cantidad = 0.5
                        }
                        console.log(store.state.pedidos.detalles_update[i].cantidad);
                        console.log(detalle_pedido.cantidad);
                        
                        store.state.pedidos.detalles_update[i].cantidad =  parseFloat(store.state.pedidos.detalles_update[i].cantidad) + parseFloat(detalle_pedido.cantidad);

                        break; //Stop this loop, we found it!
                    }
                }
                //Actualizo la cantidad para mostrar

                for (var i in detalles_pedido) {
                    // es el id del detalle
                    if (detalles_pedido[i].producto && detalles_pedido[i].producto.id == detalle.producto.id) {
                        if (detalle.producto.categoriaArbol.name.includes("PIZZA")){

                            detalle_pedido.cantidad = 0.5
                        }
                        detalles_pedido[i].cantidad = parseFloat(detalles_pedido[i].cantidad) +  parseFloat(detalle_pedido.cantidad);
                        break;
                    }
                }
            }

        }
        else {
            console.log("No existe el detalle para enviar al server, es un detalle nuevo");

            let isDetalle = detalles_pedido.find(d =>  d.producto && d.producto.id == detalle.producto.id)
            console.log("DETALLE: ",detalle);
            console.log("isDETALLE",isDetalle);
            // if (moduloInventario && isDetalle.producto.controlaStock) {
            //     if (detalle.producto.cantidadstock >= parseInt(detalle.cantidad)) {
            //         detalles[i].cantidad = detalle.cantidad
            //         store.commit('pedidos/set_detalle_repetido')
            //     }else{
            //         console.warn("Stock insuficiente")
            //     }
            // }

            if (isDetalle) {
                var cantidad = parseFloat(isDetalle.cantidad)
                var detalle_pedido_original = {
                    detallePedido_id: isDetalle.id,
                    producto: detalle.producto.id,
                    cantidad: parseFloat(isDetalle.cantidad),
                    accion: 2
                }
                if (detalle.producto.categoriaArbol.name.includes("PIZZA")) {
                    cantidad = 0.5;
                } else if (moduloInventario && detalle.producto.controlaStock) {
                    if (detalle.producto.cantidadstock >= parseInt(detalle.cantidad)) {
                        cantidad = detalle.cantidad;
                    } else {
                        console.warn("Stock insuficiente");
                    }
                } else {
                    console.log("Existe en el detalle original.");
                    cantidad = detalle.cantidad;
                }



                //Se tiene que agregar en los detalles que se envian al servidor porque no existian
                //como una modificacion
                //Actualizo la cantidad para mostrar
                // store.commit('pedidos/add_detalle_update', detalle_pedido_original);
                // for (var i in detalles_pedido) {
                //     // es el id del detalle
                //     if (detalles_pedido[i].producto && detalles_pedido[i].producto.id == detalle.producto.id) {

                //         detalles_pedido[i].cantidad += cantidad;
                //         break;
                //     }
                // }
                isDetalle.cantidad  += cantidad;
                var productoId = detalle.producto.id
                store.commit('pedidos/UPDATE_CANTIDAD', { productoId, cantidad });

                store.commit('pedidos/set_detalle_repetido')


            }
            else {
                if (detalle.producto.categoriaArbol.name.includes("PIZZA")) {
                    detalle_pedido.cantidad = 0.5;
                } else if (moduloInventario && detalle.producto.controlaStock) {
                    if (detalle.producto.cantidadstock >= parseInt(detalle.cantidad)) {
                        detalle_pedido.cantidad = detalle.cantidad;
                    } else {
                        console.warn("Stock insuficiente");
                    }
                } else {
                    detalle_pedido.cantidad = detalle.cantidad;
                }
                
                store.commit('pedidos/add_detalle_update', detalle_pedido);
                detalle.cantidad = detalle_pedido.cantidad
                detalles_pedido.push(detalle);

                // state.detalles_views_update.push(detalle);
            }

        }
    } else {
        let detalle_pedido = {
            promocion: detalle.promocion.id,
            cantidad: detalle.cantidad,
            accion: 2
        }

        let existeDetalle = store.state.pedidos.detalles_update.find(d => d.promocion == detalle_pedido.promocion);
        if (existeDetalle) {
            // console.log("Existe el detalle para enviar, es: ", existeDetalle);

            //El detalle existia y fue eliminado
            if (existeDetalle.accion == 0) {
                // console.log("Esta en el server, y fue eliminado");
                // console.log("Pero ahora se agrego de nuevo, y es modificado");


                //Tiene que cambiar a la accion de modificar, y setear la cantidad
                for (var i in store.state.pedidos.detalles_update) {
                    if (store.state.pedidos.detalles_update[i].promocion && store.state.pedidos.detalles_update[i].promocion == detalle.promocion.id) {
                        store.state.pedidos.detalles_update[i].accion = 1
                        store.state.pedidos.detalles_update[i].cantidad = detalle_pedido.cantidad;

                        break; //Stop this loop, we found it!
                    }
                }
                //Tambien debe mostrarlo
                detalles_pedido.push(detalle);


            } else {
                // console.log("Existe el detalle, y fue modificado");

                // Si existe para enviar y no fue por que se elimino
                //Actualizo la cantidad para enviar
                for (var i in store.state.pedidos.detalles_update) {
                    if (store.state.pedidos.detalles_update[i].promocion && store.state.pedidos.detalles_update[i].promocion == detalle.promocion.id) {

                        store.state.pedidos.detalles_update[i].cantidad = detalle_pedido.cantidad;

                        break; //Stop this loop, we found it!
                    }
                }
                //Actualizo la cantidad para mostrar

                for (var i in detalles_pedido) {
                    // es el id del detalle
                    if (detalles_pedido[i].promocion && detalles_pedido[i].promocion.id == detalle.promocion.id) {

                        detalles_pedido[i].cantidad = detalle_pedido.cantidad;
                        break;
                    }
                }
            }

        }
        else {
            // console.log("No existe el detalle para enviar, es un detalle nuevo");


            let isDetalle = detalles_pedido.find(d => d.promocion && d.promocion.id == detalle.promocion.id)


            if (isDetalle) {
                let detalle_pedido = {
                    detallePedido_id: isDetalle.id,
                    promocion: detalle.promocion.id,
                    cantidad: detalle.cantidad,
                    accion: 1
                }
                //Se tiene que agregar en los detalles que se envian al servidor porque no existian
                //como una modificacion
                //Actualizo la cantidad para mostrar
                store.commit('pedidos/add_detalle_update', detalle_pedido);
                for (var i in detalles_pedido) {
                    // es el id del detalle
                    if (detalles_pedido[i].promocion && detalles_pedido[i].promocion.id == detalle.promocion.id) {

                        detalles_pedido[i].cantidad = detalle_pedido.cantidad;
                        break;
                    }
                }

                store.commit('pedidos/set_detalle_repetido')


            }
            else {
                store.commit('pedidos/add_detalle_update', detalle_pedido);
                detalles_pedido.push(detalle);

                // state.detalles_views_update.push(detalle);
            }

        }
    }
}

function update_detalle_update(detalles_pedido, detalle) {

    if (detalle.producto) {

        let detalle_pedido = {
            detallePedido_id: detalle.id,
            producto: detalle.producto.id,
            cantidad: detalle.cantidad,
            accion: 1
        }


        // Busca si ya existe el detalle 
        let existeDetalle = store.state.pedidos.detalles_update.find(d => d.producto && d.producto == detalle_pedido.producto);

        if (existeDetalle) {

            for (var i in store.state.pedidos.detalles_update) {
                if (store.state.pedidos.detalles_update[i].producto && store.state.pedidos.detalles_update[i].producto == detalle.producto.id) {

                    store.state.pedidos.detalles_update[i].cantidad = detalle_pedido.cantidad;

                    break; //Stop this loop, we found it!
                }
            }

            for (var i in detalles_pedido) {
                // es el id del detalle
                if (detalles_pedido[i].producto && detalles_pedido[i].producto.id == detalle.producto.id) {

                    detalles_pedido[i].cantidad = detalle_pedido.cantidad;
                    break;
                }
            }
        }
        else {
            // console.log("No existe el detalle para enviar al server");
            store.commit('pedidos/add_detalle_update', detalle_pedido);
            for (var i in detalles_pedido) {
                // es el id del detalle
                if (detalles_pedido[i].producto && detalles_pedido[i].producto.id == detalle.producto.id) {

                    detalles_pedido[i].cantidad = detalle_pedido.cantidad;
                    break;
                }
            }


        }
    } else {
        // Promocion

        let detalle_pedido = {
            detallePedido_id: detalle.id,
            promocion: detalle.promocion.id,
            cantidad: detalle.cantidad,
            accion: 1
        }


        // Busca si ya existe el detalle 
        let existeDetalle = store.state.pedidos.detalles_update.find(d => d.promocion == detalle_pedido.promocion);

        if (existeDetalle) {

            for (var i in store.state.pedidos.detalles_update) {
                if (store.state.pedidos.detalles_update[i].promocion && store.state.pedidos.detalles_update[i].promocion == detalle.promocion.id) {

                    store.state.pedidos.detalles_update[i].cantidad = detalle_pedido.cantidad;

                    break; //Stop this loop, we found it!
                }
            }

            for (var i in detalles_pedido) {
                // es el id del detalle
                if (detalles_pedido[i].promocion && detalles_pedido[i].promocion.id == detalle.promocion.id) {

                    detalles_pedido[i].cantidad = detalle_pedido.cantidad;
                    break;
                }
            }
        }
        else {
            // console.log("No existe el detalle para enviar al server");
            store.commit('pedidos/add_detalle_update', detalle_pedido);
            for (var i in detalles_pedido) {
                // es el id del detalle
                if (detalles_pedido[i].promocion && detalles_pedido[i].promocion.id == detalle.promocion.id) {

                    detalles_pedido[i].cantidad = detalle_pedido.cantidad;
                    break;
                }
            }


        }
    }



}





export {
    quit_detalle_update,
    update_detalle_update, add_detalle_update, deshacer_eliminado
}