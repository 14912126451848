<template>
  <v-card height="500px">
    <v-container>
      <v-card-title class="mt-0 pt-0 pb-0">
        <v-spacer></v-spacer>
        <v-icon @click="$emit('close-dialog')">mdi-close</v-icon></v-card-title
      >
      <v-row>
        <v-col cols="6">
          <v-navigation-drawer
            color="transparent"
            height="450px"
            width="380px"
            permanent
          >
            <template v-slot:prepend>
              <v-list-item class="ma-0">
                <v-list-item-avatar>
                  <button-imprimir
                    :id="pedido.id"
                    tipoComprobante="PE"
                  ></button-imprimir>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="text-h5">
                      Pedido nº. {{ pedido.numeroVentaPorArqueo }}
                    </div>
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption">
                    <v-alert
                      dense
                      :color="
                        pedido.getEstadosActual == 'PENDIENTE'
                          ? 'orange'
                          : 'blue'
                      "
                      class="mt-0 mb-0 pt-0 pb-0"
                    >
                      {{
                        pedido.getEstadosActual == "PENDIENTE"
                          ? "En producción"
                          : pedido.getEstadosActual
                      }}
                    </v-alert></v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-icon
                  ><v-btn @click="editarPedido(pedido)" outlined>
                    Editar
                    <v-icon class="mr-2">mdi-pencil</v-icon>
                  </v-btn></v-list-item-icon
                >
              </v-list-item>
              <v-spacer></v-spacer>
              <v-list v-if="pedido.cliente" class="pa-0" dense>
                <v-list-item dense>
                  <v-list-item-avatar class="mb-0 mt-0"
                    ><v-icon left>mdi-account</v-icon></v-list-item-avatar
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle class="caption">
                      Cliente
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{ pedido.cliente.full_name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-avatar class="mb-0 mt-0"
                    ><v-icon left>mdi-phone</v-icon></v-list-item-avatar
                  >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ pedido.telefono }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list v-show="pedido.paraEnviar">
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon>mdi-truck-delivery</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>Para enviar</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-list-item-subtitle></v-list-item-subtitle>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon>mdi-google-maps</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        >{{ pedido.cliente.direccion.calle }}
                        {{ pedido.cliente.direccion.altura }}
                        <span v-show="pedido.cliente.direccion.barrio.nombre"
                          >-</span
                        >
                        {{
                          pedido.cliente.direccion.barrio.nombre
                        }}</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-list-item-subtitle></v-list-item-subtitle>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-list>
              <v-list v-if="pedido.nombreEntidad" class="pa-0" dense>
                <v-list-item dense>
                  <v-list-item-avatar class="mb-0 mt-0"
                    ><v-icon left>mdi-account</v-icon></v-list-item-avatar
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle class="caption">
                      Cliente
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{ pedido.nombreEntidad.cliente.nombre }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-avatar class="mb-0 mt-0"
                    ><v-icon left>mdi-phone</v-icon></v-list-item-avatar
                  >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ pedido.nombreEntidad.cliente.telefono }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list v-show="pedido.paraEnviar">
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon>mdi-truck-delivery</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>Para enviar</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-list-item-subtitle></v-list-item-subtitle>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon>mdi-google-maps</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        >{{ pedido.nombreEntidad.envio.calle }}
                        {{ pedido.nombreEntidad.envio.altura }}
                        <span v-show="pedido.nombreEntidad.envio.barrio.nombre"
                          >-</span
                        >
                        {{
                          pedido.nombreEntidad.envio.barrio.nombre
                        }}</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-list-item-subtitle></v-list-item-subtitle>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-list>
            </template>

            <template v-slot:append>
              <v-divider></v-divider>

              <pedido-estados-siguientes
                @process-refresh="refreshPedido"
                :pedido="pedido"
                @process-cobrar="dialogCobrar = true"
                @process-quitar-pedido="quitarPedido"
                @close-dialog="cerrarDialogo"
              ></pedido-estados-siguientes>
            </template>
          </v-navigation-drawer>
        </v-col>
        <v-col cols="6">
          <pedido-detalle-list
            :detalles="pedido.detalles_pedido"
          ></pedido-detalle-list>
          <pedido-detalle-subtotal
            :detalles="pedido.detalles_pedido"
            :tipoRedondeo="'entero'"
          ></pedido-detalle-subtotal>
        </v-col>
      </v-row>
    </v-container>
    <dialog-confirmation-close
      v-if="dialogPedidoCancelar"
      @close-dialog="dialogPedidoCancelar = false"
      @process-accept="cancelarPedido"
    >
      <template v-slot:titulo>¿Cancelar el pedido?</template>
      <template v-slot:message
        >¿Seguro que quieres cancelar el pedido? No podrá revertir los
        cambios.</template
      >
      <template v-slot:buttonOK>Cancelar pedido</template>
    </dialog-confirmation-close>
    <v-dialog width="500" v-model="dialogCobrar">
      <PedidoVentaCobrar
        :formasDePago="formasDePago"
        v-if="dialogCobrar"
        ref="ventaCobro"
        :tipoRedondeo="'entero'"
        :detallesVenta="pedido.detalles_pedido"
        @process-register="pagarPedido"
        @close-dialog="dialogCobrar = false"
      ></PedidoVentaCobrar>
    </v-dialog>
  </v-card>
</template>

<script>
import VentaCobrar from "@/components/venta/cobro/venta.cobrar.vue";
import PedidoVentaCobrar from "@/components/pedido/venta/PedidoVentaCobro.vue";

import DialogConfirmationClose from "@/components/dialogs/confirmation.close";
import PedidoDetalleList from "@/components/pedido/actualizar/pedido.detalle.list.vue";
import PedidoDetalleSubtotal from "@/components/pedido/actualizar/pedido.detalle.subtotal.vue";
import PedidoEstadosSiguientes from "@/components/pedido/actualizar/pedido.estados.siguientes.vue";
import { mapActions, mapGetters } from "vuex";
import PedidoApi from "@/api/venta/pedido";
import ButtonImprimir from "@/components/tareas/buttonImprimir.vue";

export default {
  name: "pedido.venta.vista-previa2",
  components: {
    ButtonImprimir,
    DialogConfirmationClose,
    PedidoDetalleList,
    PedidoDetalleSubtotal,
    PedidoEstadosSiguientes,
    PedidoVentaCobrar,
  },
  props: {
    pedido: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogPedidoCancelar: false,
      loadingButton: false,
      dialogCobrar: false,
    };
  },
  computed: {
    ...mapGetters("comprobantes", ["formasDePago"]),

    estado() {
      return this.pedido.getEstadosActual;
    },
  },

  methods: {
    ...mapActions("pedidos", ["fetch_pedidos", "actualizar_estado_pedido"]),
    quitarPedido(pedido) {
      this.$emit("process-quitar-pedido", pedido);
      this.cerrarDialogo();
    },
    editarPedido() {
      this.$emit("process-ver-pedido", this.pedido);
    },
    refreshPedido(estado) {
      this.$emit("process-refresh", estado);
      this.cerrarDialogo();
    },
    cerrarDialogo() {
      this.$emit("close-dialog");
    },

    async pagarPedido(dataVentaCobro) {
      try {
        await PedidoApi.pagar(this.pedido.id, dataVentaCobro);
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Pedido cobrado",
          icon: "mdi-check",
          timeout: 2000,
        });
        this.dialogCobrar = false;
        this.$refs.ventaCobro.setLoading(false);
        this.$refs.ventaCobro.reset(false);
        this.refreshPedido(this.estado);
      } catch (e) {
        this.$refs.ventaCobro.setLoading(false);
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          top: true,
          color: "info",
          left: false,
          right: true,
          text: e.response.data,
          icon: "mdi-information",
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<style scoped>
#buttonAccion {
  text-transform: lowercase;
}

#buttonAccion > span {
  display: inline-block;
}

#buttonAccion > span:first-letter {
  text-transform: uppercase;
}
</style>
