<template>
  <div>
    <p>Seleccionar cuenta</p>
    <PlanDeCuentaSeleccionar
      :cuenta="editedItem.cuenta"
      @set:cuenta="setCuenta"
    ></PlanDeCuentaSeleccionar>
    <p>Seleccionar fecha</p>
    <DatePickerMultiple @process-select="setDateFilter" />
    <v-template> </v-template>
    <DetalleCuenta
      v-for="cuenta in cuentas_contables"
      :key="cuenta.cuenta_codigo"
      :cuenta="cuenta"
      fechaDesde="2024-01-01"
      fechaHasta="2024-12-31"
      :dateFilter="dateFilter"
    />
  </div>
</template>
  
  <script>
import DetalleCuenta from "@/components/contabilidad/reporte/ReporteCuentaContableDetalle.vue";
import PlanDeCuentaSeleccionar from "@/components/contabilidad/PlanDeCuentaSeleccionar.vue";
import DatePickerMultiple from "@/components/reportes/DatePickerMultiple";

export default {
  components: {
    DetalleCuenta,
    PlanDeCuentaSeleccionar,
    DatePickerMultiple,
  },
  data() {
    return {
      editedItem: {
        cuenta: null,
        debe: "",
        haber: "",
        comentario: "",
      },
      cuentas_contables: [],
      dateFilter: null,
    };
  },
  methods: {
    setDateFilter(filter) {
      this.dateFilter = filter;
    },
    setCuenta(cuenta) {
      if (cuenta) {
        
        this.editedItem.cuenta = cuenta[0];
        this.cuentaError = "";
        this.fetchDetalleCuenta()
        return;
      }
      this.editedItem.cuenta = null;
    },
    fetchDetalleCuenta() {
      this.loading = true;
      this.error = null;
      

      const url = `/reportes/reportedetallecuentacontable?cuenta=${this.editedItem.cuenta.id}&${this.dateFilter}`;

      this.axios
        .get(url)
        .then((response) => {
          // Si el array está vacío, asigna un valor predeterminado
          if (response.data.length > 0) {
            this.cuentas_contables = response.data;
          } else {
            this.cuentas_contables = [];
          }
        })
        .catch((error) => {
          this.error = error.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchDetalleCuenta();
  },
  watch: {
    dateFilter(val) {
      this.fetchDetalleCuenta();
    },
  },
};
</script>
  