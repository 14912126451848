<template>
  <div>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon class="grey lighten-1" dark> mdi-folder </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
          {{moment(asiento.fechaAlta).format("DD/MM/YY HH:mm")}} - {{ asiento.numero }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <div>
            <v-btn icon>
              <v-btn icon @click="goToEditar(asiento)">
                <v-icon color="grey lighten-1">mdi-pencil</v-icon></v-btn
              >
            </v-btn>
            <v-btn icon>
              <v-btn icon @click="dialogEliminar = true">
                <v-icon color="grey lighten-1">mdi-delete</v-icon></v-btn
              >
            </v-btn>
          </div>
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <AsientoTipoDetalleItem :detalles="asiento.detalles"></AsientoTipoDetalleItem>
          
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <dialog-confirmation-close
      v-if="dialogEliminar"
      @close-dialog="dialogEliminar = false"
      @process-accept="eliminarAsiento()"
    >
      <template v-slot:titulo>¿Eliminar el asiento?</template>
      <template v-slot:message
        >¿Seguro que quieres eliminar el asiento "{{ asiento.descripcion }}"? No
        podrá revertir los cambios.</template
      >
      <template v-slot:buttonOK>Eliminar</template>
    </dialog-confirmation-close>
  </div>
</template>

<script>
import DialogConfirmationClose from "@/components/dialogs/confirmation.close";
import AsientoTipoDetalleItem from '@/components/contabilidad/asiento/asientotipo/AsientoTipoDetalleItem.vue'

import { mapActions, mapState } from "vuex";

export default {
  name: "AsientoTipoListItem",
  components: {
    DialogConfirmationClose,
    AsientoTipoDetalleItem
  },
  props: {
    asiento: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogEliminar: false,
    };
  },

  methods: {
    ...mapActions("comprobantes", ["eliminar_asiento_contable"]),
    goToEditar(asiento) {
      this.$router.push({
        name: "asientoMantenimientoUpdate",

        params: { asiento: asiento },
      });
    },
    eliminarAsiento() {
      this.eliminar_asiento_contable(this.asiento).then((response) => {
        console.log(response);
        this.dialogEliminar = false;
      }).catch(e => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: `${e.response.data.detail}`,
            icon: "mdi-information",
            timeout: 3000,
          });
        this.dialogEliminar = false;

      })
    },
  },
};
</script>