<template>
  <LayoutBasic :showMenu="false">
    <template v-slot:up-title>Productos</template>
      <template v-slot:navigation-title> Nuevo producto </template>
    <template v-slot:title>
      <v-list>
        <v-list-item class="pa-0">
          <v-list-item-title>
            <h1 style="font-size: 30px">Nuevo producto</h1>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
    </template>
 <template v-slot:navigation-controller>
      <v-list-item >
        <v-list-item-icon>
          <v-icon >mdi-info</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            ><span class="grey--text">Facturado</span></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:navigation-controller>
      <v-list>
        <v-list-item-group mandatory :value="true">
          <v-list-item>
            <v-list-item-icon>
              <v-btn icon style="background-color: #1877f2">
                <v-icon>mdi-tag</v-icon>
              </v-btn>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Elige el tipo de registro</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>

    <template v-slot:vista-previa>
      <v-container>
        <v-row justify="center" style="height: 100vh; max-height: calc(100vh - 64px)">
          <v-col cols="10">
            <div class="d-flex align-center justify-center mb-6" style="height: 100%">
              <v-row justify="center">
                <v-col>
                  <h2>Elige el tipo de registro</h2>

                  <v-row class="mt-1" justify="start">
                    <v-col cols="4">
                      <v-hover v-slot:default="{ hover }">
                        <v-card
                          @click="$router.push({ name: 'nuevoProductoSimple' })"
                          height="258px"
                          width="240px"
                          class="d-flex align-center"
                          :color="hover ? '#313233' : ''"
                        >
                          <v-container class="text-center">
                            <div>
                              <v-icon color="blue" large>mdi-tag</v-icon>
                            </div>
                            <h3>SIMPLE</h3>
                            <span style="color: #bdbdbd"
                              >Registrar <strong>un</strong> solo producto
                            </span>
                          </v-container>
                        </v-card>
                      </v-hover>
                    </v-col>
                    <v-col cols="4">
                      <v-hover v-slot:default="{ hover }">
                        <v-card
                          @click="$router.push({ name: 'nuevoProductoMultiple' })"
                          height="258px"
                          width="240px"
                          class="d-flex align-center"
                          :color="hover ? '#313233' : ''"
                          :disabled="true"
                        >
                          <v-container class="text-center">
                            <div>
                              <v-icon color="blue" large>mdi-tag-multiple</v-icon>
                            </div>
                            <h3>Múltiple</h3>
                            <span style="color: #bdbdbd"
                              >Registrar <strong> varios</strong> productos a la vez
                            </span>
                          </v-container>
                        </v-card>
                      </v-hover>
                    </v-col>
                     <v-col cols="4">
                      <v-hover v-slot:default="{ hover }">
                        <v-card
                          @click="$router.push({ name: 'productoslistadeprecios' })"
                          height="258px"
                          width="240px"
                          class="d-flex align-center"
                          :color="hover ? '#313233' : ''"
                          :disabled="true"

                        >
                          <v-container class="text-center">
                            <div>
                              <v-icon color="blue" large>mdi-tag-multiple</v-icon>
                            </div>
                            <h3>Lista genérica</h3>
                            <span style="color: #bdbdbd"
                              >Registrar <strong> varios</strong> productos a la vez
                            </span>
                          </v-container>
                        </v-card>
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </LayoutBasic>
</template>

<script>
import LayoutProductoRegistrar from "@/layouts/productos/layout.registrar.producto";
import LayoutBasic from "@/layouts/LayoutBasic.vue";

import LayoutRegistrarProducto from "@/layouts/productos/LayoutRegistrarProducto";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "producto.registrar",
  components: {
    LayoutProductoRegistrar,
    LayoutRegistrarProducto,
    LayoutBasic
  },
  data() {
    return {
      modelMenu: 1,
      loadingButtonRegister: false,
      singleRegister: 0,
      loading: false,
      items: [
        {
          icon: "mdi-arrow-left",
          text: "Listado de productos",
          route: "productos",
        },
        {
          icon: "mdi-folder-plus",
          text: "Nuevo Producto",
          route: "nuevoProducto",
        },

        {
          icon: "mdi-chart-donut",
          text: "Historial",
        },
      ],
    };
  },

  methods: {
    ...mapActions("productos", ["fetch_productos_mas_vendidos"]),

    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
    sendRegister() {
      this.$refs.refNuevoProducto.validateForm();
    },
    async fetchProductosMasVendidos() {
      const productos = await this.fetch_productos_mas_vendidos();
    },
    registrarProducto(data) {
      this.axios
        .post(`/productos/p`, data)
        .then((response) => {
          // this.update_product(data);
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Producto registrado",
            icon: "mdi-check",
            timeout: 3000,
          });
          this.$router.push("/productos");
          this.fetchProductosMasVendidos();
          this.loading = false;

          // this.$emit("process-product-register-success");
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: "Problema inesperado",
            icon: "mdi-information",
            timeout: 3000,
          });
          this.loading = false;

          // this.$router.push({
          //   name: "MensajeRegistroError",
          //   params: { error: e.response.data },
          // });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
