<template>
  <layout-registrar-producto>
    <template v-slot:up-title> Productos > Registrar > Simple </template>

    <template v-slot:title>
      <v-list>
        <v-list-item class="pa-0">
          <v-list-item-title>
            <h1 style="font-size: 30px">Registrar producto</h1>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
    </template>

    <template v-slot:navigation-controller>
      <v-container>
        <template v-if="step == 1" name="step-1">
          <form-producto-es-elaboracion-propia
            :outlined="true"
            :producto="producto"
          >
          </form-producto-es-elaboracion-propia>
          <form-producto-proveedor
            @set_proveedor="setProveedor"
            :outlined="true"
            :producto="producto"
          >
          </form-producto-proveedor>
          <form-producto-categoria
            @set_categoria="setCategoria"
            :producto="producto"
          >
          </form-producto-categoria>
          <form-producto-marca
            @set_marca="setMarca"
            :outlined="true"
            :producto="producto"
          >
          </form-producto-marca>
          <div>
            <v-divider></v-divider>
            <div class="mt-2">
              <b>Nombre del producto</b>
            </div>
            <div style="font-size: 14px">Ingrese el nombre del producto.</div>
            <div>
              <form-producto-descripcion :producto="producto">
              </form-producto-descripcion>
            </div>
          </div>
          <div>
            <v-divider></v-divider>
            <div class="mt-1">
              <b>Código de barra</b>
            </div>
            <div style="font-size: 14px">
              Escanea el código de barra del producto o escríbelo manualmente.
            </div>
            <div class="mt-1">
              <form-producto-barcode
                :producto="producto"
              ></form-producto-barcode>
            </div>
          </div>
        </template>
        <template name="step-2" v-if="step == 2">
          <div name="Precio de costo">
            <div>
              <b>Precio de costo</b>
            </div>
            <div style="font-size: 14px">
              Ingresa el precio de costo o compra del producto.
            </div>
            <div class="mt-1">
              <form-producto-precio-costo
                :producto="producto"
                :outlined="true"
              ></form-producto-precio-costo>
            </div>
          </div>
          <div name="Ganancia">
            <v-divider></v-divider>
            <div class="mt-1">
              <b>Ganancia del producto</b>
            </div>
            <div style="font-size: 14px">
              Ingresa el procentaje de ganancia que tendrá el producto.
            </div>
            <div class="mt-1">
              <form-producto-ganancia :producto="producto" :outlined="true">
              </form-producto-ganancia>
            </div>
          </div>
          <div name="Flete">
            <v-divider></v-divider>
            <div class="mt-1">
              <b>Flete</b>
            </div>
            <div style="font-size: 14px">
              Ingresa un porcentaje de costo extra que lleva el producto.
            </div>
            <div class="mt-1">
              <form-producto-flete
                :producto="producto"
                :outlined="true"
              ></form-producto-flete>
            </div>
          </div>



        </template>
        <template name="step-3" v-if="step == 3">
          <!-- Sección: Rubro de Venta -->
          <div>
            <h4>Rubro de Venta</h4>
            <v-divider></v-divider>
            <div class="mt-1">
              <RubroVentaListSelect
                :value="producto.rubro_venta"
                @process-select="setRubroVenta"
                title="Seleccionar Rubro Venta"
              ></RubroVentaListSelect>
            </div>
            <div class="mt-0">
              <b>IVA Venta</b>
            </div>
            <div style="font-size: 14px">Impuesto sobre el valor añadido</div>
            <div class="mt-1">
              <AlicuotaIvaSelect
                class="mt-1 mb-1"
                :iva="producto.iva"
                :isEdit="false"
                @update:iva="setIva(producto, $event)"
              ></AlicuotaIvaSelect>
            </div>
          </div>

          <!-- Sección: Rubro de Compra -->
          <div class="mt-4">
            <h4>Rubro de Compra</h4>
            <v-divider></v-divider>
            <div class="mt-1">
              <RubroCompraListSelect
                :value="producto.rubro_compra"
                @process-select="setRubroCompra"
                title="Seleccionar Rubro Compra"
              ></RubroCompraListSelect>
            </div>
            <div class="mt-0">
              <b>IVA Compra</b>
            </div>
            <div style="font-size: 14px">Impuesto sobre el valor añadido</div>
            <div class="mt-1">
              <AlicuotaIvaSelect
                :isEdit="false"
                :iva="producto.rubro_compra.iva"
                @update:iva="setIvaCompra(producto, $event)"
              ></AlicuotaIvaSelect>
            </div>
          </div>

        </template>
           <template name="step-4" v-if="step == 4">
          <div name="Precio de venta calculado" class="mt-3">
            <v-divider></v-divider>
            <div class="mt-5">
              <b>Precio de Venta Manual</b>
            </div>
            <div style="font-size: 14px">
              Este es el precio de venta que has ingresado manualmente. Se
              redondeado al entero más cercano.
            </div>
            <div class="mt-1">
              <FormProductoPrecioVenta
                :tipoRedondeo="'entero'"
                :producto="producto"
                :outlined="true"
              ></FormProductoPrecioVenta>
            </div>
          </div>

          <div name="Precio de venta calculado">
            <v-divider></v-divider>
            <div class="mt-1">
              <b>Precio de Venta Calculado</b>
            </div>
            <div style="font-size: 14px">
              Este es el precio de venta calculado automáticamente basado en el
              costo y la ganancia. Está redondeado a dos decimales.
            </div>
            <div class="mt-1">
              <FormProductoPrecioVenta
                :producto="producto"
                :outlined="true"
                :disabled="true"
                :tipoRedondeo="'decimales'"
              ></FormProductoPrecioVenta>
            </div>
          </div>
          <v-list dense class="mt-0 pt-0">
            <v-list-item class="mt-0 pt-0">
              <v-list-item-avatar>
                <v-img
                  src="@/assets/pedidosya/pedidoya.png"
                  max-height="30"
                  max-width="30"
                  contain
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <div style="font-weight: 600">Vender en PedidosYA</div>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox
                  dense
                  hide-details=""
                  class="text-center justify-center"
                  v-model="producto.esPedidoYa"
                  value
                >
                </v-checkbox>
              </v-list-item-action>
            </v-list-item>

            <v-list-item v-show="producto.esPedidoYa">
              <v-list-item-content>
                <v-list-item-title>
                  <v-text-field
                    :prefix="producto.porcentajePY ? '%' : ''"
                    v-model="producto.porcentajePY"
                    v-validate="'decimal:2|max:30'"
                    :error-messages="errors.collect('porcentaje')"
                    data-vv-name="porcentaje"
                    type="number"
                    label="Porcentaje de racargo"
                    outlined
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    @change="calcularPrecioPY()"
                    maxlength="5"
                    class="ma-1"
                  ></v-text-field>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-container>
    </template>
    <template v-slot:navigation-stepper>
      <v-row>
        <v-col>
          <v-progress-linear
            color="indigo"
            rounded
            value="100"
            height="8"
          ></v-progress-linear>
        </v-col>
        <v-col>
          <v-progress-linear
            color="indigo"
            rounded
            :value="step > 1 ? '100' : '0'"
            height="8"
          ></v-progress-linear>
        </v-col>
        <v-col>
          <v-progress-linear
            color="indigo"
            rounded
            :value="step > 2 ? '100' : '0'"
            height="8"
          ></v-progress-linear>
        </v-col>
        <v-col>
          <v-progress-linear
            color="indigo"
            rounded
            :value="step > 3 ? '100' : '0'"
            height="8"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </template>
    <template v-slot:navigation-append="{ reload }">
      <v-btn
        v-show="step == 1"
        large
        class="mb-2"
        color="primary"
        @click="
          validateForm(2);
          reload();
        "
        block
        ><b style="font-size: 17px">Siguiente</b>
      </v-btn>
      <v-row>
        <v-col>
          <v-btn
            large
            v-show="step == 2"
            class="mb-2"
            @click="
              nextStep(1);
              reload();
            "
            block
            ><b style="font-size: 17px">Anterior</b></v-btn
          >
        </v-col>
        <v-col>
          <v-btn
            large
            v-show="step == 2"
            class="mb-2"
            color="primary"
            @click="validateForm(3)"
            block
            ><b style="font-size: 17px">Siguiente</b></v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            large
            v-show="step == 3"
            class="mb-2"
            @click="
              nextStep(2);
              reload();
            "
            block
            ><b style="font-size: 17px">Anterior</b></v-btn
          >
        </v-col>
        <v-col>
          <v-btn
            large
            v-show="step == 3"
            class="mb-2"
            color="primary"
            @click="validateForm(4)"
            block
            ><b style="font-size: 17px">Siguiente</b></v-btn
          >
        </v-col>
      </v-row>
      <v-row v-show="step == 4">
        <v-col>
          <v-btn
            large
            v-show="step == 4"
            class="mb-2"
            @click="nextStep(3)"
            block
            ><b style="font-size: 17px">Anterior</b></v-btn
          >
        </v-col>
        <v-col>
          <v-btn
            v-show="step == 4"
            large
            class="mb-2"
            color="success"
            :loading="loading"
            :disabled="errors.any()"
            @click="validateForm()"
            block
            ><b style="font-size: 17px">Registrar</b>
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <template v-slot:vista-previa>
      <v-container fill-height fluid>
        <v-row justify="center" align="center" class="mt-2 mb-2 ml-2 mr-2">
          <v-col cols="12">
            <v-card>
              <v-container>
                <div class="mb-2">Vista previa</div>
                <producto-vista-previa
                  :marca="marca"
                  :categoria="categoria"
                  :proveedor="proveedor"
                  :producto="producto"
                  :precioPY="calcularPrecioPY()"
                ></producto-vista-previa>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </layout-registrar-producto>
</template>

<script>
import { administrarMixin } from "@/mixins/producto/administrarMixin.js";

import LayoutRegistrarProducto from "@/layouts/productos/layout.registrar.producto";
import ProductoVistaPrevia from "@/components/producto/producto.vista.previa.vue";
import AlicuotaIvaSelect from "@/components/util/AlicuotaIvaSelect.vue";

import FormProductoProveedor from "@/components/producto/form/form.producto.proveedor.vue";
import FormProductoCategoria from "@/components/producto/form/form.producto.categoria.vue";
import FormProductoDescripcion from "@/components/producto/form/form.producto.descripcion.vue";
import FormProductoBarcode from "@/components/producto/form/form.producto.barcode.vue";

import ProductoForm from "@/components/producto/form/producto.form";
import FormProductoMarca from "@/components/producto/form/form.producto.marca.vue";
import FormProductoPrecioCosto from "@/components/producto/form/form.producto.precio.costo.vue";
import FormProductoGanancia from "@/components/producto/form/form.producto.ganancia.vue";
import FormProductoIva from "@/components/producto/form/form.producto.iva.vue";

import FormProductoFlete from "@/components/producto/form/form.producto.flete.vue";
import FormProductoPrecioVenta from "@/components/producto/form/form.producto.precio.venta.vue";
import FormProductoInventario from "@/components/producto/form/form.producto.inventario.vue";

import { mapActions } from "vuex";
import FormProductoEsElaboracionPropia from "@/components/producto/form/FormProductoEsElaboracionPropia.vue";

import RubroVentaListSelect from "@/components/contabilidad/RubroVentaListSelect.vue";
import RubroCompraListSelect from "@/components/contabilidad/RubroCompraListSelect.vue";

export default {
  name: "ProductoHomeRegistrarSingle",
  components: {
    AlicuotaIvaSelect,
    FormProductoCategoria,
    FormProductoDescripcion,
    FormProductoBarcode,
    ProductoForm,
    LayoutRegistrarProducto,
    FormProductoProveedor,
    FormProductoMarca,
    FormProductoPrecioCosto,
    FormProductoGanancia,
    FormProductoIva,
    FormProductoFlete,
    FormProductoPrecioVenta,
    ProductoVistaPrevia,
    FormProductoInventario,
    FormProductoEsElaboracionPropia,
    RubroVentaListSelect,
    RubroCompraListSelect,
  },
  $_veeValidate: {
    validator: "new",
  },
  mixins: [administrarMixin],
  data() {
    return {};
  },
  methods: {
    ...mapActions("productos", ["registrar_producto_simple"]),
    setIva(producto, vatType) {
      producto.iva = vatType;
    },
    setIvaCompra(producto, vatType) {
      producto.iva_compra = vatType;
    },
    setRubroCompra(rubro) {
      var { id, iva } = rubro;
      this.producto.rubro_compra.id = id;
      this.producto.rubro_compra.iva = iva;
      this.producto.iva_compra = iva;
    },
    setRubroVenta(rubro) {
      var { id, iva } = rubro;
      this.producto.rubro_venta.id = id;
      this.producto.rubro_venta.iva = iva;
      this.producto.iva = iva;
    },

    registrarProducto() {
      console.log(this.producto);

      this.registrar_producto_simple(this.producto)

        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Producto registrado",
            icon: "mdi-check",
            timeout: 3000,
          });
          this.$router.push("/productos");
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: `${e.response.data}`,
            icon: "mdi-information",
            timeout: 3000,
          });
          this.loading = false;
          if (
            e.response.data.includes("Ya existe un producto con este nombre")
          ) {
            this.resetProducto();
          }
          this.step = 1;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
