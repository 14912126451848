<template>
  <div>
    <v-menu
      v-model="menuPeriodo"
      transition="scale-transition"
      offset-x
      :close-on-content-click="true"
      right
    >
      <template v-slot:activator="{ on, attrs }">
        <!-- <span class="sc-dropdown-group__label">Período</span> -->
        <v-btn outlined v-bind="attrs" v-on="on"
          >{{ selected }} <v-icon right> $expand </v-icon></v-btn
        >
      </template>
      <v-list>
        <v-list-item-group v-model="listSelection">
          <v-list-item
            @click="selectOption(item.key)"
            v-for="item in items"
            :key="item.id"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.date }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-menu
          v-model="menuFechaDesde"
          :close-on-content-click="false"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-title>
                {{ dates.length == 0 ? "Fecha personalizada" : TextDateOrder }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <v-date-picker
            locale="es"
            :max="moment().format('YYYY-MM-DD')"
            v-model="dates"
            range
          >
            <v-spacer></v-spacer>

            <v-btn
              block
              large
              color="primary"
              @click="selectOption('personalizada'), (menuFechaDesde = false)"
            >
              Aplicar
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "DatePickerMultiple",
  data() {
    return {
      menuFechaDesde: false,
      fechaDesde: new Date().toISOString().substr(0, 10),
      items: [
        { id: 0, text: "Hoy", key: "hoy", date: this.moment().format("ll") },

        {
          id: 1,
          text: "Últimos 7 días",
          key: "lastWeek",
          date: `${this.moment()
            .subtract(7, "d")
            .format("ll")} al ${this.moment().format("ll")} `,
        },
      ],
      menuPeriodo: false,
      model: 0,
      selected: "",
      dates: [],
    };
  },
  computed: {
    dateOrder() {
      return this.dates.sort(function (a, b) {
        return new Date(a).getTime() - new Date(b).getTime();
      });
    },
    TextDateOrder() {
      var date = null;
      if (this.dates.length == 2) {
        date = `${this.moment(this.dateOrder[0]).format("ll")} al ${this.moment(
          this.dateOrder[1]
        ).format("ll")}`;
      } else if (this.dates.length == 1) {
        date = `${this.moment(this.dates[0]).format("ll")}`;
      }
      return date;
    },
    listSelection: {
      get: function () {
        return this.model;
      },
      set: function (newValue) {
        this.model = newValue;
        var value = this.items.find((item) => item.id === newValue);
        this.selected = value.text;
      },
    },
  },
  methods: {
    selectOption(filters) {
      var filter = null;
      switch (filters) {
        case "personalizada":
          this.selected = this.TextDateOrder;
          this.menuPeriodo = false;
          this.model = 2;
          if (this.dates.length == 2) {
            filter = `from_current=${this.moment(this.dateOrder[0]).format(
              "YYYY-MM-DD"
            )}&to_current=${this.moment(this.dateOrder[1]).format(
              "YYYY-MM-DD"
            )}`;
            break;
          } else if (this.dates.length == 1) {
            filter = `current=${this.moment(this.dates[0]).format(
              "YYYY-MM-DD"
            )}`;
            break;
          }
        case "hoy":
          // filter = `current=${this.moment().format("YYYY-MM-DD")}`;
          filter = `from_current=${this.moment().format("YYYY-MM-DD")}&to_current=${this.moment().format("YYYY-MM-DD")}`;
          break;

        case "lastWeek":
          filter = `from_current=${this.moment()
            .subtract(7, "d")
            .format("YYYY-MM-DD")}&to_current=${this.moment().format(
            "YYYY-MM-DD"
          )}`;
          break;

        default:
          filter = `current=${this.moment().format("YYYY-MM-DD")}`;
      }
      this.$emit("process-select", filter);
    },
  },
  mounted() {
    this.model = 1;
    this.selected = this.items[1].text;
    let filter = `from_current=${this.moment()
            .subtract(7, "d")
            .format("YYYY-MM-DD")}&to_current=${this.moment().format(
            "YYYY-MM-DD"
          )}`;
    this.$emit("process-select", filter);
  },
};
</script>

<style lang="scss" scoped>
</style>