<template>
  <div>
    <!-- Campo para ingresar el precio de venta manual -->
    <v-text-field
      color="green"
      class="title"
      :prefix="producto.precioVenta ? '$' : ''"
      id="precioVenta"
      v-model="producto.precioVenta"
      :label="precioVentaLabel"
      v-validate="`required|decimal:2|max:30|greater_than_cost:${producto.precioCosto}`"
      :error-messages="errors.collect('precioVenta')"
      data-vv-name="precioVenta"
      type="number"
      :outlined="outlined"
      :disabled="disabled"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: "form.producto.precio.venta",
  inject: ["$validator"],
  

  props: {
    producto: {
      type: Object,
      required: true, // Objeto que contiene los datos del producto
    },
    outlined: {
      type: Boolean,
      default: false, // Define si el campo tiene un borde
    },
    disabled: {
      type: Boolean,
      default: false, // Define si el campo está deshabilitado
    },
    tipoRedondeo: {
      type: String,
      default: "entero", // Tipo de redondeo: "entero" o "decimales"
      validator: (value) => ["decimales", "entero"].includes(value),
    },
  },

  computed: {
    /**
     * Etiqueta dinámica del campo de precio de venta
     */
    precioVentaLabel() {
      return this.tipoRedondeo === "entero"
        ? "Precio de Venta Manual"
        : "Precio de Venta Calculado";
    },

    /**
     * Calcula automáticamente la ganancia en porcentaje
     */
    calcularGanancia() {
      
      const costoTotal = this.calcularCostoTotal();

      // Validar que ambos valores existan y sean mayores a cero
      if (costoTotal > 0 && this.producto.precioVenta > 0) {
        // Eliminar IVA del precio de venta para obtener el precio base
        const ivaVenta =
          this.producto.iva
            ? parseFloat(this.producto.iva.description) / 100
            : 0;

        const precioVentaSinIVA =
          this.producto.precioVenta / (1 + ivaVenta);

        // Calcular el margen de ganancia en porcentaje
        const ganancia = (precioVentaSinIVA / costoTotal - 1) * 100;

        this.producto.ganancia =  parseFloat(ganancia.toFixed(2)) 
        return parseFloat(ganancia.toFixed(2));
      }
      return 0; // Si no se puede calcular, retorna 0
    },
  },

  methods: {
    /**
     * Calcula el costo total del producto (precio costo + flete + otros costos)
     */
    calcularCostoTotal() {
      const precioCosto = this.producto.precioCosto || 0;
      const flete = this.producto.flete
        ? parseFloat(this.producto.flete) / 100
        : 0;

      // Costo ajustado con el flete
      return precioCosto * (1 + flete);
    },
  },
  watch: {
    'producto.precioVenta': {
      handler(val) {
        if (val != null) {
          let ganancia = this.calcularGanancia();
          this.producto.ganancia = Math.round(ganancia * 100) / 100;

        }
      },
    },
  },
};
</script>

<style scoped>
/* Estilos específicos para este componente */
.title {
  margin-bottom: 16px;
}

p {
  font-size: 14px;
  color: #555;
}
</style>
