<template>
  <div>
    <h3 class="text-center">MAYOR DE CUENTAS</h3>
    <div v-if="loading">Cargando datos...</div>
    <div v-if="error" class="error">{{ error }}</div>
    <div class="cuenta-contable">
      <h2>Cuenta: {{ cuenta.cuenta_nombre }} ({{ cuenta.cuenta_codigo }})</h2>
      <p>Saldo acumulado: {{ cuenta.saldo_acumulado | convertPesoArg }}</p>
      <p>{{ formatDateRange(this.dateFilter) }}</p>

      <table class="table">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Asiento</th>
            <th>Descripción</th>
            <th>Debe</th>
            <th>Haber</th>
            <th>saldo mensual</th>
            <th>saldo_acumulado</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="detalle in cuenta.registros" :key="detalle.nroasiento">
            <td>{{ moment(detalle.fecha).format("l") }}</td>
            <td>{{ detalle.nroasiento }}</td>
            <td>{{ detalle.descripcion }}</td>
            <td>
              <span v-if="detalle.debe !== 0">{{
                detalle.debe | convertPesoArg
              }}</span>
            </td>
            <td>
              <span v-if="detalle.haber !== 0">{{
                detalle.haber | convertPesoArg
              }}</span>
            </td>
            <td>{{ detalle.saldo_mensual | convertPesoArg }}</td>
            <td>{{ detalle.saldo_acumulado | convertPesoArg }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="3"><strong>Totales</strong></td>
            <td>{{ cuenta.total_debe | convertPesoArg }}</td>
            <td>{{ cuenta.total_haber | convertPesoArg }}</td>
            <td colspan="2"></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "DetalleCuenta",
  data() {
    return {
      cuenta: null, // Almacenará los resultados
      loading: false, // Indicador de carga
      error: null, // Mensaje de error
    };
  },
  props: {
    cuenta: {
      type: Object,
      required: true,
    },
    fechaDesde: {
      type: String,
      required: true,
    },
    fechaHasta: {
      type: String,
      required: true,
    },
    dateFilter: {
      type: String,
      required: true,
    },
  },
  methods: {
    formatDateRange(dateFilter) {
      console.log(dateFilter);

      const params = new URLSearchParams(dateFilter);
      const fromDate = params.get("from_current");
      const toDate = params.get("to_current");

      // Formatear las fechas (puedes usar moment.js o Intl.DateTimeFormat si necesitas más formatos)
      const formattedFromDate = new Date(fromDate).toLocaleDateString("es-AR");
      const formattedToDate = new Date(toDate).toLocaleDateString("es-AR");

      return `Diario General Desde el ${formattedFromDate} hasta el ${formattedToDate}`;
    },
  },
};
</script>
  
  <style>
.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}
.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.table th {
  background-color: #5378aa;
}
.error {
  color: red;
}
</style>
  