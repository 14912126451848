<template>
  
      <v-hover open-delay="5" v-slot="{ hover }">
        <v-card :color="hover ? '#626262' : ''" :elevation="hover ? 16 : 2" @click="goToUpdateProduct(producto)"
          height="100%">
          <v-container>
            <v-chip v-if="!producto.tiene_contabilidad">Requiere actualización</v-chip>
            <v-chip v-if="producto.es_precio_diferente">Verificar precio de venta</v-chip>
            <v-chip v-if="producto.contabilidad && !producto.contabilidad.es_sucursal_valida_rubro_compra ">Rubro compra no pertenece a sucursal </v-chip>
            <v-chip v-if="producto.contabilidad && !producto.contabilidad.es_sucursal_valida_rubro_venta ">Rubro venta no pertenece a sucursal </v-chip>
            
            <v-img max-height="100" contain v-bind:src="imagen.noImage">
              <v-app-bar flat color="rgba(0, 0, 0, 0)">


                <v-toolbar-title v-if="producto.parent" class="text-h6 white--text pl-0">
                  <v-chip small>Fracción</v-chip>
                </v-toolbar-title>

                <v-spacer></v-spacer>


                <!-- <v-btn v-if="hover" text>Editar
              <v-icon>mdi-pen</v-icon></v-btn> -->
              </v-app-bar>
            </v-img>

            <v-card-text>
             
              <div style="font-size: 25px">
                {{ producto.precioVenta | convertPesoArg }}
              </div>
              <v-chip color="transparent" v-if="producto.esPedidoYa" class="pr-1 pl-1" small label>
                <v-img src="@/assets/pedidosya/pedidoya.png" max-height="15" max-width="15" contain></v-img>
                <div class="pl-1" style="font-size: 15px">
                  {{ calcularPrecioPY(producto) | convertPesoArg }}
                </div>
              </v-chip>
            </v-card-text>

            <v-card-text>
              <v-row justify="center" align="center">
                <div>
                  <h2>{{ producto.descripcion }}</h2>
                </div>
              </v-row>
              <v-row v-if="producto.controlaStock && inventario" justify="center" align="center">
                <div class="mt-1">Stock: {{ producto.cantidadstock }}</div>
              </v-row>
            </v-card-text>
          </v-container>
        </v-card>
      </v-hover>
          

</template>

<script>
// @ is an alias to /src
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "ListadoProducto",
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  props: {
    producto: {
      type: Object,
      required: true,
      default: null,
    },
   
  },
  data() {
    return {
      icons: ["mdi-rewind", "mdi-play", "mdi-fast-forward"],
      imagen: {
        noImage: require("@/assets/no-image.png"),
      },
     
    };
  },
  computed: {
  
    ...mapState("sucursales", ["configuracion"]),
    inventario() {
      return this.configuracion.productoInventario;
    },

  },
  methods: {
    goToUpdateProduct(producto) {
      this.$emit("process-producto", producto);
    },
    calcularPrecioPY(producto) {
      return Math.round(
        producto.precioVenta + (producto.precioVenta * producto.porcentajePY) / 100
      );
    },

  },
};
</script>
